import React from 'react';
import tw, { styled } from 'twin.macro';

const HoursContainer = styled.div`
  ${tw`text-base bg-primary-300 leading-tight mb-8 p-2 text-center text-gray-100 font-light`}
`;
const HoursTitle = styled.h2`
  ${tw`m-0 text-2xl text-center uppercase font-light`}
`;
const Hours = styled.p`
  ${tw`m-0 p-0 text-lg text-center font-light`}
`;

type HoursBlockProps = {
  hours: string[];
};

const HoursBlock = ({ hours = [''] }: HoursBlockProps) => (
  <HoursContainer>
    <HoursTitle>Now Open</HoursTitle>
    {hours &&
      hours.map((hourString) => <Hours key={hourString}>{hourString}</Hours>)}
  </HoursContainer>
);

export default HoursBlock;
