import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
// eslint-disable-next-line no-unused-vars
import tw, { styled } from 'twin.macro';
import { ImageSlide } from '../entities/image-slide';
import useCompanyInfo from '../hooks/use-company-info';
import PageTemplate from '../components/page-template';
import AddressCard from '../components/address-card';
import SEO from '../components/seo';
import StaticMapFluid from '../components/static-map-fluid';
import HoursBlock from '../components/hours-block';
import ImageSlider from '../components/image-slider';
// import CardContainer from '../components/card-container';
import {
  FlexContainer,
  PageSection,
  TitleBar,
  TextContainer,
  PageTitle,
  SectionTitle,
} from '../components/ui-elements';

const CategoryDescription = styled.div`
  ${tw`text-lg leading-relaxed`}
  & > ul, & > div > ul {
    ${tw`list-disc list-inside`}
  }
`;

type SanityAsset = {
  alt: string;
  asset: IGatsbyImageData;
};

type GraphQLData = {
  page: {
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
    _rawBody: string;
    lightbox: {
      lightboxDisplay: string;
      slides: ImageSlide[];
    };
    bowshop: {
      title: string;
      _rawBody: string;
      image: SanityAsset;
    };
    range: {
      title: string;
      _rawBody: string;
      image: SanityAsset;
    };
  };
  staticMap: {
    childFile: {
      childImageSharp: IGatsbyImageData;
    };
  };
};

type ArcheryPageProps = PageProps<GraphQLData>;

function ArcheryPage(props: ArcheryPageProps) {
  const { data } = props;
  const {
    page: { seo, _rawBody, lightbox, bowshop, range },
    staticMap,
  } = data;
  const { hours, mapMeta } = useCompanyInfo();
  const bowshopImage = getImage(bowshop.image.asset);
  const rangeImage = getImage(range.image.asset);

  return (
    <PageTemplate>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="text-primary-100">{seo.title}</PageTitle>
      </TitleBar>
      <PageSection tw="md:mt-8">
        <FlexContainer>
          <TextContainer tw="md:leading-relaxed md:pr-8 md:w-2/3">
            <BlockContent blocks={_rawBody} />
          </TextContainer>
          <ImageSlider lightbox={lightbox} interval={4000} size="2/3" />
        </FlexContainer>
      </PageSection>
      <PageSection tw="w-full bg-primary-300 text-gray-100 py-8 md:my-8">
        <FlexContainer tw="md:px-4">
          <div tw="md:w-1/3">
            <div tw="mb-12">
              <HoursBlock hours={hours} />
            </div>
            <div tw="my-8">
              <AddressCard />
            </div>
          </div>
          <div tw="md:w-1/3">
            <StaticMapFluid
              fluid={staticMap.childFile.childImageSharp}
              located={mapMeta.mapText}
              mapUrl={mapMeta.mapUrl}
            />
          </div>
        </FlexContainer>
      </PageSection>
      <PageSection tw="md:mt-8">
        <FlexContainer>
          <div tw="py-8 md:w-1/2 md:pr-12">
            <SectionTitle tw="md:text-center">{bowshop.title}</SectionTitle>
            {bowshopImage && (
              <GatsbyImage
                image={bowshopImage}
                alt={bowshop.image.alt}
                tw="rounded mb-8"
              />
            )}
            <CategoryDescription>
              <BlockContent blocks={bowshop._rawBody} />
            </CategoryDescription>
          </div>
          <div tw="py-8 md:w-1/2 md:pl-12">
            <SectionTitle tw="md:text-center">{range.title}</SectionTitle>
            {rangeImage && (
              <GatsbyImage
                image={rangeImage}
                alt={range.image.alt}
                tw="rounded mb-8"
              />
            )}
            <CategoryDescription>
              <BlockContent blocks={range._rawBody} />
            </CategoryDescription>
          </div>
        </FlexContainer>
      </PageSection>
    </PageTemplate>
  );
}

export default ArcheryPage;

export const query = graphql`
  query ArcheryPageQuery {
    page: sanityArcheryPage {
      seo {
        title
        description
        keywords
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      lightbox {
        lightboxDisplay
        slides {
          _key
          alt
          caption
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
      bowshop {
        title
        _rawBody(resolveReferences: { maxDepth: 10 })
        image {
          alt
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
      range {
        title
        _rawBody(resolveReferences: { maxDepth: 10 })
        image {
          alt
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
    staticMap(nickname: { eq: "homePage" }) {
      childFile {
        childImageSharp {
          id
          gatsbyImageData(width: 350, layout: CONSTRAINED)
        }
      }
    }
  }
`;
