import React from 'react';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../utils/awesome-slider-override.css';
import tw, { styled } from 'twin.macro';
import { ImageSlide } from '../entities/image-slide';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const SliderContainer = styled.div`
  ${tw`w-full pb-16 md:pb-4`}
`;

type ImageSliderProps = {
  lightbox: {
    lightboxDisplay: string;
    slides: ImageSlide[];
  };
  interval?: Number;
  size?: String;
};

const ImageSlider = (props: ImageSliderProps) => {
  const {
    lightbox: { lightboxDisplay = '', slides = [] },
    interval = 4000,
    size = '1/3',
  } = props;

  const slider = (
    <AutoplaySlider play cancelOnInteraction interval={interval}>
      {slides.length > 0 &&
        slides.map((img: ImageSlide) => (
          <div key={img._key} data-src={getSrc(img.asset)} />
        ))}
    </AutoplaySlider>
  );

  return (
    <SliderContainer className={` md:w-${size}`}>
      <div tw="w-full">
        {lightboxDisplay === 'display-all' ? (
          slider
        ) : (
          <GatsbyImage
            image={slides[0].asset}
            alt={slides[0].alt}
            tw="rounded mb-8"
          />
        )}
      </div>
    </SliderContainer>
  );
};

export default ImageSlider;
