import React from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
// eslint-disable-next-line no-unused-vars
import tw, { styled } from 'twin.macro';

const MapLink = styled.a`
  ${tw`text-center`}
`;

type StaticMapProps = {
  alt?: string;
  fluid: IGatsbyImageData;
  linkText?: string;
  located?: string;
  mapUrl?: string;
};

const StaticMapFluid = ({
  fluid,
  located,
  mapUrl,
  linkText = 'View in Google Maps',
  alt = 'Google static map of Glenwood Springs Outdoors location',
}: StaticMapProps) => {
  const staticMapImage = getImage(fluid);

  return (
    <div tw="w-full">
      {located && <p tw="uppercase text-sm text-center">{located}</p>}
      {staticMapImage && (
        <GatsbyImage
          image={staticMapImage}
          alt={alt}
          tw="rounded border-2 border-gray-100 shadow-md mb-4"
        />
      )}
      {mapUrl && (
        <MapLink
          href={mapUrl}
          tw="block underline uppercase text-base text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </MapLink>
      )}
    </div>
  );
};

export default StaticMapFluid;
